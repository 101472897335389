import moment from "moment";
import { IAccessRules, TimeFormat } from "types";
import { hourFormatToTimeFormat } from "../hour-format-to-time-format";

export const restrictedAreaAllowedBookingStart = (
  accessRules: IAccessRules,
  userHourFormat?: TimeFormat,
): string => {
  const restrictedTime = accessRules?.restrictedTime || 0;
  const hours = Math.floor(restrictedTime / 60 / 60);
  const minutes = Math.floor(restrictedTime / 60 - hours * 60);
  const localTime = moment()
    .startOf("day")
    .set("hours", hours)
    .set("minutes", minutes)
    .local();
  const hourMinutes = localTime.format(
    hourFormatToTimeFormat(userHourFormat || TimeFormat.TWENTY_FOUR_HOUR),
  );

  return hourMinutes;
};
