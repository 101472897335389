import {
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  SimpleChanges,
  inject,
} from "@angular/core";
import {
  QueryParamsHandling,
  RouterLink,
  RouterLinkActive,
} from "@angular/router";
import { MenuItemComponent } from "../menu-item/menu-item.component";
import { FeatureFlagModel, TeamsService } from "auth-module";
import { Subscription, take } from "rxjs";
import { RouterModel } from "router-module";
import { NgClass } from "@angular/common";

@Component({
  selector: "db-menu-item-with-children",
  templateUrl: "./menu-item-with-children.component.html",
  styleUrls: ["./menu-item-with-children.component.scss"],
  standalone: true,
  imports: [NgClass, RouterLink, RouterLinkActive],
})
export class MenuItemWithChildrenComponent {
  // NOTE: prevent default behavior for html title attribute
  @HostBinding("attr.title") get getTitle(): null {
    return null;
  }

  @ContentChildren(MenuItemComponent)
  childMenuItems!: QueryList<MenuItemComponent>;

  @Input() link: string | any[] = "";
  @Input() linkActive = "active";
  @Input() linkActiveOptions: { exact: boolean } = { exact: false };
  @Input() queryParamsHandling: QueryParamsHandling = "";
  @Input() skipLocationChange = false;
  @Input() loading = false;
  @Input() badge = "";

  @Input() linkClass: string[] = [];

  @Input() imgSrc: string | null = null;
  @Input() title = "";
  @Input() hideIcons = false;

  @Input() disabled = false;

  featureFlagModel = inject(FeatureFlagModel);
  teamsService = inject(TeamsService);
  routerModel = inject(RouterModel);
  teamsAppInitialized$ = this.teamsService.initialized$;
  sub = new Subscription();
  showIcon = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["hideIcons"]) {
      this.teamsAppInitialized$
        .pipe(take(1))
        .subscribe((teamsAppInitialized) => {
          if (this.hideIcons && teamsAppInitialized) {
            this.showIcon = false;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
