<div class="autocomplete-users">
  <p-autoComplete
    [delay]="0"
    [(ngModel)]="currentSelection"
    [dataKey]="'key'"
    [optionLabel]="'key'"
    [suggestions]="suggestions"
    (completeMethod)="onSearch($event)"
    [multiple]="true"
    [placeholder]="placeholder"
    [size]="'small'"
    [forceSelection]="true"
    [autoOptionFocus]="true"
    [showClear]="true"
    [emptyMessage]="emptyMessage"
    [minLength]="1"
    [scrollHeight]="'320px'"
    [lazy]="true"
    [style]="{ width: '462px', 'max-width': '462px' }"
    [panelStyle]="{ 'max-width': '462px' }"
    (onSelect)="onSelect()"
    (onUnselect)="onUnselect()"
    (onClear)="onClearAll()"
    (onBlur)="onBlur()"
    [attr.data-testid]="dataTestId"
  >
    <ng-template let-suggestion pTemplate="item">
      <div
        class="d-flex align-items-center"
        [ngStyle]="{ 'max-width': '462px' }"
      >
        @switch (suggestion.type) { @case ('user') {
        <db-user-info
          [style]="{ width: '100%' }"
          [userInfo]="suggestion.user"
          [showUserEmail]="true"
          [fullWidth]="true"
          containerPadding="10px 16px"
          containerDataTestId="autocomplete--search-result"
          avatarDataTestId="autocomplete--search-result-avatar"
          [showEmailIfNameNotAvailable]="true"
          [ignoreEmailMaxWidth]="true"
        >
        </db-user-info>
        } @case ('group') {
        <div class="custom-list-item">
          <div class="icon group-icon">
            <i class="pi pi-users"></i>
          </div>
          <span class="title">
            {{ suggestion.group.name }} ({{ suggestion.group.userCount }})
          </span>
        </div>
        } @case ('externalEmail') {
        <div class="custom-list-item">
          <div class="icon email-icon">
            <i class="svg-email"></i>
          </div>
          <span class="title">
            {{ suggestion.email }}
          </span>
        </div>
        } }
      </div>
    </ng-template>
    <ng-template let-suggestion pTemplate="selectedItem">
      @switch (suggestion.type) { @case ('user') {
      <db-avatar-chip
        class="d-inline-flex"
        [label]="
          suggestion.user.firstName
            ? suggestion.user.firstName + ' ' + suggestion.user.lastName
            : suggestion.user.email
        "
        [avatarColor]="suggestion.user.avatarColor"
        [avatarImgSrc]="suggestion.user.profileImage"
        [size]="'normal'"
        [attr.data-testid]="dataTestId + '--selected-item'"
      ></db-avatar-chip>
      } @case ('group') {
      <div class="custom-pill">
        <div class="icon group-icon">
          <i class="pi pi-users"></i>
        </div>
        <span class="title">
          {{ suggestion.group.name }} ({{ suggestion.group.userCount }})
        </span>
        @if ( enableUserSelection && suggestion.group.userCount > 0 &&
        suggestion.group.userCount < groupTooltipExpandUserLimit ) {
        <i
          class="pi pi-window-maximize expand-icon"
          [pTooltip]="groupTooltipContent[suggestion.group.id] || ''"
          tooltipPosition="top"
          (mouseenter)="onGroupTooltipHover(suggestion.group)"
          (click)="onGroupTooltipClick(suggestion.group)"
        ></i>
        }
      </div>
      } @case ('externalEmail') {
      <div class="custom-pill">
        <div class="icon email-icon">
          <i class="svg-email"></i>
        </div>
        <span class="title">
          {{ suggestion.email }}
        </span>
      </div>
      } }
    </ng-template>
    <ng-template pTemplate="clearicon">
      <i class="pi pi-times"></i>
    </ng-template>
    <ng-template pTemplate="removetokenicon">
      <i class="svg-delete-sign remove-pill-icon"></i>
    </ng-template>
  </p-autoComplete>
</div>
