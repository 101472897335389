import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from "primeng/autocomplete";
import { TooltipModule } from "primeng/tooltip";
import { UserService } from "projects/user-module/src/lib/user.service";
import { Subject, takeUntil, combineLatest, of, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  map,
} from "rxjs/operators";
import { IUserInfo } from "types";
import { AvatarChipComponent } from "../avatar-chip/avatar-chip.component";
import { UserInfoComponent } from "../user-info/user-info.component";
import { IUserGroupSearchResult } from "common-module";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const SEARCH_DEBOUNCE_TIME = 200;
const DEFAULT_LIMIT = 100;

export type SelectionItem =
  | {
      type: "user";
      key: string;
      user: IUserInfo;
    }
  | {
      type: "group";
      key: string;
      group: IUserGroupSearchResult;
    }
  | {
      type: "externalEmail";
      key: string;
      email: string;
    };

@Component({
  selector: "db-autocomplete-users",
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    AvatarChipComponent,
    UserInfoComponent,
    TooltipModule,
  ],
  templateUrl: "./autocomplete-users.component.html",
  styleUrls: ["./autocomplete-users.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnChanges, OnDestroy {
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly destroy$ = new Subject<void>();
  private readonly searchSubject = new Subject<string>();

  @Input() companyId!: string;
  @Input() placeholder!: string;
  @Input() emptyMessage!: string;
  @Input() limit = DEFAULT_LIMIT;
  @Input() excludeUserIds: string[] = [];
  @Input() userService!: UserService;
  @Input() dataTestId!: string;

  @Input() enableUserSelection = true;
  @Input() enableGroupSelection = false;
  @Input() enableExternalEmailSelection = false;

  @Input() initiallySelectedUsers: IUserInfo[] = [];
  @Input() initiallySelectedUsersIds: string[] = [];
  @Input() initiallySelectedGroups: IUserGroupSearchResult[] = [];
  @Input() initiallySelectedGroupsIds: string[] = [];
  @Input() initiallySelectedExternalEmails: string[] = [];

  @Output() selectionChange = new EventEmitter<SelectionItem[]>();

  private initiallySelectedUserIdsTriggered = false;
  private initiallySelectedGroupsIdsTriggered = false;

  currentSelection: SelectionItem[] = [];
  suggestions: SelectionItem[] = [];

  groupTooltipContent: { [groupId: string]: string } = {};
  groupTooltipDisplayUserLimit = 15;
  groupTooltipExpandUserLimit = 100;

  constructor() {
    this.initializeSearchSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["initiallySelectedUsers"] ||
      changes["initiallySelectedUsersIds"] ||
      changes["initiallySelectedGroups"] ||
      changes["initiallySelectedGroupsIds"] ||
      changes["initiallySelectedExternalEmails"]
    ) {
      this.initializeSelectedItems();
    }
  }

  private initializeSelectedItems(): void {
    this.initializeSelectedUsers();
    this.initializeSelectedGroups();
    this.initializeSelectedExternalEmails();
    this.emitCurrentSelection();
  }

  private initializeSelectedUsers(): void {
    if (
      this.initiallySelectedUsersIds.length > 0 &&
      !this.initiallySelectedUserIdsTriggered &&
      this.companyId &&
      this.enableUserSelection
    ) {
      this.initiallySelectedUserIdsTriggered = true;
      this.userService
        .loadUsersForCompanyFiltered_v3({
          companyId: this.companyId,
          userIds: this.initiallySelectedUsersIds,
          offset: 0,
          limit: this.initiallySelectedUsersIds.length,
        })
        .pipe(
          map((response) => response.data),
          takeUntil(this.destroy$),
        )
        .subscribe((users) => {
          this.currentSelection.push(
            ...users.map(this.mapUserToSelectionItem.bind(this)),
          );
          this.emitCurrentSelection();
          this.cdRef.detectChanges();
        });
    }

    if (this.initiallySelectedUsers.length > 0 && this.enableUserSelection) {
      this.currentSelection.push(
        ...this.initiallySelectedUsers.map(
          this.mapUserToSelectionItem.bind(this),
        ),
      );
    }
  }

  private initializeSelectedGroups(): void {
    if (
      this.initiallySelectedGroupsIds.length > 0 &&
      !this.initiallySelectedGroupsIdsTriggered &&
      this.enableGroupSelection
    ) {
      this.initiallySelectedGroupsIdsTriggered = true;
      this.userService
        .loadGroupsForCompanyFiltered({
          companyId: this.companyId,
          groupIds: this.initiallySelectedGroupsIds,
          include: ["userCount"],
          offset: 0,
          limit: this.initiallySelectedGroupsIds.length,
        })
        .pipe(
          map((response) => response.data),
          takeUntil(this.destroy$),
        )
        .subscribe((groups) => {
          this.currentSelection.push(
            ...groups.map(this.mapGroupToSelectionItem.bind(this)),
          );
          this.emitCurrentSelection();
          this.cdRef.detectChanges();
        });
    }

    if (this.initiallySelectedGroups.length > 0 && this.enableGroupSelection) {
      this.currentSelection.push(
        ...this.initiallySelectedGroups.map(
          this.mapGroupToSelectionItem.bind(this),
        ),
      );
    }
  }

  private initializeSelectedExternalEmails(): void {
    if (
      this.initiallySelectedExternalEmails.length > 0 &&
      this.enableExternalEmailSelection
    ) {
      this.currentSelection.push(
        ...this.initiallySelectedExternalEmails.map(
          this.mapEmailToSelectionItem.bind(this),
        ),
      );
    }
  }

  private initializeSearchSubscription(): void {
    this.searchSubject
      .pipe(
        startWith(""),
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(SEARCH_DEBOUNCE_TIME),
        switchMap((searchValue) => {
          if (!searchValue || !this.companyId) {
            return of([]);
          }

          let usersRequest$: Observable<IUserInfo[]> = of([]);
          if (this.enableUserSelection) {
            usersRequest$ = this.userService
              .loadUsersForCompanyFiltered_v3({
                companyId: this.companyId,
                searchQuery: searchValue,
                excludeUserIds: [
                  ...this.excludeUserIds,
                  ...this.getSelectedUserIds(),
                ],
                offset: 0,
                limit: this.limit,
              })
              .pipe(map((response) => response.data));
          }

          let groupsRequest$: Observable<IUserGroupSearchResult[]> = of([]);
          if (this.enableGroupSelection) {
            groupsRequest$ = this.userService
              .loadGroupsForCompanyFiltered({
                companyId: this.companyId,
                searchQuery: searchValue,
                excludeGroupIds: this.getSelectedGroupIds(),
                include: ["userCount"],
                offset: 0,
                limit: this.limit,
              })
              .pipe(map((response) => response.data));
          }

          return combineLatest([usersRequest$, groupsRequest$]).pipe(
            map(([users, groups]) =>
              this.addSuggestions(users, groups, searchValue),
            ),
          );
        }),
      )
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.cdRef.detectChanges();
      });
  }

  private addSuggestions(
    users: IUserInfo[],
    groups: IUserGroupSearchResult[],
    searchQuery: string,
  ): SelectionItem[] {
    const suggestedUsers = users.map(this.mapUserToSelectionItem.bind(this));
    const suggestedGroups = groups.map(this.mapGroupToSelectionItem.bind(this));

    // If the searched email matches no user, add it as an external email
    const suggestedEmails: SelectionItem[] = [];
    if (this.enableExternalEmailSelection && suggestedGroups.length === 0) {
      const selectedEmails = this.getSelectedEmails();
      if (
        EMAIL_REGEX.test(searchQuery) &&
        !selectedEmails.includes(searchQuery.toLowerCase())
      ) {
        suggestedEmails.push(this.mapEmailToSelectionItem(searchQuery));
      }
    }

    return [...suggestedGroups, ...suggestedUsers, ...suggestedEmails];
  }

  onSearch(event: AutoCompleteCompleteEvent): void {
    this.searchSubject.next(event?.query?.trim());
  }

  onSelect(): void {
    this.emitCurrentSelection();
    this.searchSubject.next("");
  }

  onBlur(): void {
    this.searchSubject.next("");
  }

  onUnselect(): void {
    this.emitCurrentSelection();
  }

  onClearAll(): void {
    this.currentSelection = [];
    this.emitCurrentSelection();
  }

  private emitCurrentSelection(): void {
    this.selectionChange.emit(this.currentSelection);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private mapUserToSelectionItem(user: IUserInfo): SelectionItem {
    return {
      type: "user",
      key: `user-${user.id}`,
      user,
    };
  }

  private mapGroupToSelectionItem(
    group: IUserGroupSearchResult,
  ): SelectionItem {
    return {
      type: "group",
      key: `group-${group.id}`,
      group,
    };
  }

  private mapEmailToSelectionItem(email: string): SelectionItem {
    return {
      type: "externalEmail",
      key: `externalEmail-${email}`,
      email: email.toLowerCase(),
    };
  }

  private getSelectedUserIds(): string[] {
    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "user" }> =>
          item.type === "user",
      )
      .map((item) => item.user.id!);
  }

  private getSelectedGroupIds(): string[] {
    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "group" }> =>
          item.type === "group",
      )
      .map((item) => item.group.id);
  }

  private getSelectedEmails(): string[] {
    return this.currentSelection
      .filter(
        (item): item is Extract<SelectionItem, { type: "externalEmail" }> =>
          item.type === "externalEmail",
      )
      .map((item) => item.email.toLowerCase());
  }

  onGroupTooltipHover(group: IUserGroupSearchResult) {
    if (group.id in this.groupTooltipContent) {
      return;
    }

    this.groupTooltipContent[group.id] = "";

    this.userService
      .loadUsersForCompanyFiltered_v3({
        companyId: this.companyId,
        userGroups: [group.id],
        offset: 0,
        limit: this.groupTooltipDisplayUserLimit,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        if (!users.total) {
          return;
        }

        let tooltipContent =
          $localize`:@@db-ui|autocomplete-users|tooltip-click-to-expand:Click to expand into users:` +
          "\n" +
          users.data
            .map((user) => `${user.firstName} ${user.lastName}`)
            .join("\n");

        if (users.total > this.groupTooltipDisplayUserLimit) {
          const remainingCount =
            users.total - this.groupTooltipDisplayUserLimit;
          tooltipContent +=
            "\n" +
            $localize`:@@db-ui|autocomplete-users|tooltip-plus-more:(plus ${remainingCount}:count: more)`;
        }

        this.groupTooltipContent[group.id] = tooltipContent;
        this.cdRef.detectChanges();
      });
  }

  onGroupTooltipClick(group: IUserGroupSearchResult) {
    this.currentSelection = this.currentSelection.filter(
      (item) => item.type !== "group" || item.group.id !== group.id,
    );
    this.emitCurrentSelection();

    this.userService
      .loadUsersForCompanyFiltered_v3({
        companyId: this.companyId,
        userGroups: [group.id],
        offset: 0,
        limit: this.groupTooltipExpandUserLimit,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.currentSelection = [
          ...this.currentSelection,
          ...users.data
            .filter(
              (user) =>
                user.id &&
                !this.currentSelection.some(
                  (item) => item.type === "user" && item.user.id === user.id,
                ),
            )
            .map(this.mapUserToSelectionItem.bind(this)),
        ];
        this.emitCurrentSelection();
        this.cdRef.detectChanges();
      });
  }
}
